import primuslabs from "./primuslabs.png";
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={primuslabs} className="homePage" alt="" />
    </div>
  );
}

export default App;
